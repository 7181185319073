export default function () {

    const allMaps = document.querySelectorAll('.map');
    allMaps.forEach((map) => {
        if ('IntersectionObserver' in window) {
            let observer = null;

            observer = new IntersectionObserver(
                function (entries) {
                    for (var i = 0; i < entries.length; i++) {
                        if (entries[i].isIntersecting) {
                            map.setAttribute("src", map.dataset.src);
                            observer.unobserve(map);
                        }
                    }
                }
            );
            observer.observe(map);
        }
    });
}