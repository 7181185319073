export default function () {
    const allContainers = document.querySelectorAll('.free-shipment');

    allContainers.forEach(container => {
        calculateShipment(container);
    });
}
function calculateShipment(container) {
    const freeShipmentValue = parseFloat(container.getAttribute('data-free-shipment-value'));
    const currentPaymentValue = parseFloat(document.querySelector('#cart-box > span').getAttribute('data-current-payment-value'));
    const diff = +(freeShipmentValue - currentPaymentValue).toFixed(2);

    if (diff > 0) {
        container.innerHTML = `Do darmowej dostawy brakuje <b class='on'>${diff.toFixed(2).replace('.', ',')} zł</b>`;
    } else {
        container.innerHTML = `<b class="on">Dostawa gratis!</b>`;
    }
}