import showPopup from './modals';

export default function () {
    const trigger = document.querySelector('.shipping-pricing-button');
    const wysiwygHolder = document.querySelector('.shipping-pricing-info');
    if (!trigger || !wysiwygHolder) return;

    const contents = wysiwygHolder.innerHTML;
    wysiwygHolder.remove();

    trigger.addEventListener("click", function () {

        showPopup({
            header: 'Koszty dostawy',
            body: contents
        });
    });
}