import { Navigation, Manipulation } from 'swiper/modules';

let bigPhotos;
let smallPhotos;

const moveSlides = function (index) {
    const nextSlide = this.slides[index + 1];
    const prevSlide = this.slides[index - 1];
    
    if (this.slides[index].classList.contains('active')) return;

    document.querySelector('#detail-images .size-custom.active').classList.remove('active');
    this.slides[index].classList.add('active');

    if (nextSlide && !nextSlide.classList.contains('swiper-slide-visible')) {
        this.slideNext();
    }

    if (prevSlide && !prevSlide.classList.contains('swiper-slide-visible')) {
        this.slidePrev();
    }
};

export default function () {
    // PRODUCT DETAILS BIG PHOTOS
    window.addEventListener('load', () => {
        const detailImages = document.querySelector('#detail-images .product-images');

        import('swiper').then((module) => {
            const Swiper = module.default;

            bigPhotos = new Swiper(detailImages, {
                modules: [Navigation, Manipulation],
                slidesPerView: 1,
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.detail-swiper-button',
                    prevEl: '.swiper-button-prev.detail-swiper-button'
                },
                on: {
                    beforeTransitionStart: function (instance) {
                        const currentSlide = instance.slides[instance.activeIndex];
                        const slideImg = currentSlide.querySelector('img');

                        moveSlides.call(smallPhotos, instance.activeIndex);

                        if (slideImg.hasAttribute('src')) return;

                        slideImg.setAttribute('src', slideImg.getAttribute('data-src'));
                    }
                }
            });

            // PRODUCT DETAILS SMALL PHOTOS
            smallPhotos = new Swiper('.product-images-mini', {
                modules: [Manipulation],
                watchSlidesProgress: true,
                threshold: 50,
                preloadImages: false,
                on: {
                    init: function () {
                        const smallGallery = document.querySelector('.product-images-mini');

                        if (smallGallery.querySelectorAll('.size-custom').length > 1) {
                            smallGallery.classList.add('show');
                            this.slides[0].classList.add('active');
                        }
                    },
                    click: function () {
                        const clickedIndex = this.clickedIndex;

                        if (clickedIndex === undefined) return;

                        moveSlides.call(this, clickedIndex);
                        bigPhotos.slideTo(clickedIndex);
                    }
                },
                breakpoints: {
                    1: {
                        slidesPerView: 'auto',
                        direction: 'horizontal',
                        centeredSlides: true
                    },
                    1280: {
                        slidesPerView: 4,
                        direction: 'horizontal',
                        spaceBetween: 36
                    }
                }
            });
        });
    });
}

// Zmiana slajdów po wyborze wariantu
const bannersReload = (images) => {
    let bigPhotoArray = [];
    let smallPhotoArray = [];

    bigPhotos.slideTo(0, 0);
    bigPhotos.off('slideChange');
    bigPhotos.removeAllSlides();

    images.forEach(image => {
        bigPhotoArray.push(`<a data-run-fancybox title="${image.ImageAlt}" class="size-full swiper-slide" href="${image.SourcePathName}">
            <picture>
                <source media="(max-width: 600px) and (max-resolution: 1.9dppx)" srcset="${image.SmallPathName}" />
                <img class="img-contain" src="${image.FullPathName}" alt="${image.ImageAlt}" />
            </picture>
        </a>`
        );
    });

    bigPhotos.appendSlide(bigPhotoArray);
    bigPhotos.update();

    smallPhotos.removeAllSlides();
    if (images.length < 2) return;

    images.forEach(image => {
        smallPhotoArray.push(`<div class="size-custom swiper-slide">
            <img class="img-contain" alt="${image.ImageAlt}" src="${image.SmallPathName}" />
        </div>`);
    });

    smallPhotos.appendSlide(smallPhotoArray);
    smallPhotos.update();

    bigPhotos.on('slideChange', function() {
        const activeIndex = this.activeIndex;
        moveSlides.call(smallPhotos, activeIndex);
    });

    if (smallPhotos.slides[0]) {
        smallPhotos.slides[0].classList.add('active');
    }
};

export { bannersReload };