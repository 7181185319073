export default function () {

    const textContainer = document.querySelector(".text-expand-content");
    if (!textContainer) return;

    const cs = getComputedStyle(document.documentElement);
    const realHeight = textContainer.scrollHeight;
    const fontSize = +cs.getPropertyValue('--fontSize').replace("px", "");
    const lineHeight = +cs.getPropertyValue('--lineHeight');
    const maxSize = 3 * lineHeight * fontSize;

    if (realHeight > maxSize) {

        textContainer.classList.add("expandable");

        const button = textContainer.nextElementSibling;
        button.addEventListener("click", function () {
            textContainer.classList.toggle("show");
        });
    }
}